import { useState, useEffect, useRef } from "react";

// Styles
import bannerStyles from "styles/components/CreativeBanner.module.scss";

// Images
import Close from "images/ui-white-close.svg";
import Share from "images/icon-share.svg";
import Email from "images/icon-share-email.svg";
import Team from "images/icon-share-microsoft-team.svg";

type Props = {
    slug?: any;
    objectType?: any;
    pageTitle?: any;
    body?: any;
    additionalClasses?: any;
    showLabel?: boolean;
};

function useOutsideAlerter(ref: any, setShowShare: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowShare(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const ShareButton = ({ slug, objectType, pageTitle, body, additionalClasses, showLabel = false }: Props) => {
    const [showShare, setShowShare] = useState<boolean>(false);

    const msRef: any = useRef(null);
    const [pageUrl, setPageUrl] = useState<any>('');

    useEffect(() => {
        if (msRef.current && msRef.current.children.length === 0) {
            const script = document.createElement("script");

            script.src = "https://teams.microsoft.com/share/launcher.js";
            script.async = true;
            script.defer = true;
            msRef.current.innerHTML = '';
            msRef.current.appendChild(script);

            // const label: any = document.createElement('span');
            // label.innerHTML = 'Team';
            // ref.current.appendChild(label);
        }
    }, [msRef.current]);

    useEffect(() => {
        if (window) {
            if (slug && objectType) {
				let url: any;
				if ( objectType === 'creative') {
					url = window.location.origin;
				}
				else if (objectType === 'article') {
					url = window.location.href + 'articles/';
				}
				else {
					url = window.location.href;
				}
				url += slug;
                setPageUrl(url);
            }
			else {
                let url: any = window.location.href;
                if (url !== pageUrl) {
                    setPageUrl(url);
                } 
            }
        }
    }, []);

    const shareRef = useRef(null);
    useOutsideAlerter(shareRef, setShowShare);

    return (
        <div className={`share-button-container ${additionalClasses && additionalClasses}`} ref={shareRef}>
            <button className={`btn ${bannerStyles.actionButton} actionButton ${bannerStyles.shareButton} shareButton`} onClick={() => {
                showShare ? setShowShare(false) : setShowShare(true);
            }}>
                <Share />
                <span className={showLabel ? 'show-label' : ''}>Share</span>
            </button>
            <div className={`share-list-container ${showShare ? `show ${bannerStyles.show}` : ''}`}>
                <div className={`modal-background`} onClick={() => {
                    showShare ? setShowShare(false) : setShowShare(true);
                }}></div>
                <button className={`closeBtn ${bannerStyles.closeBtn}`} onClick={() => {
                    showShare ? setShowShare(false) : setShowShare(true);
                }}>
                    <Close />
                    <span>Close follow options</span>
                </button>
                <ul className={`share-list`}>
                    <li className={'email-share-button'}>
                        <a href={`mailto:?subject=${pageTitle ? pageTitle : ''}&body=${pageUrl}`}>
                            <Email />
                            <span>Email</span>
                        </a>
                    </li>
                    <li className={'ms-share-button'}>
                        <div ref={msRef} className={'teams-share-button'} data-href={pageUrl}></div>
                        <div className={'visible-button-label'}>
                            <Team />
                            <span>Teams</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ShareButton;