import React, { useRef, useState, useEffect } from "react";

//Functions
import {
    fetchUserPreferences,
    updateUserPreferences,
    resetXSRFToken
} from "helpers/functions";

// Interfaces
import {
    IfaceAllNewestResponse,
} from "helpers/interfaces";

// Styles
import styles from "styles/components/StickyCard.module.scss";
import cardStyles from "styles/components/Card.module.scss";

// Images
import Close from "images/ui-white-close.svg";
import Plus from "images/icon-plus.svg";

type Props = {
    page?: any;
    additionalClass?: any;
};

const FollowOption = (props: any) => {
    const option: any = props.option;
    const index: any = props.index;
    const type: any = 'queries';
    const setUserPreferences = props.setUserPreferences;
    const [checked, setChecked] = useState<boolean>(false);
    const [userPrefs, setUserPrefs] = useState<any>(props.userPreferences);
    const [maxDisabled, setMaxDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (props.activePrefCount >= 10 && !maxDisabled) {
            setMaxDisabled(true);
        } else if (props.activePrefCount < 10 && maxDisabled) {
            setMaxDisabled(false);
        }
    }, [props.activePrefCount]);

    useEffect(() => {
        const getUserPrefs = async () => {
            const newestPostsApi: IfaceAllNewestResponse = await fetchUserPreferences();
            if (newestPostsApi.loaded && !newestPostsApi.error) {
                const prefData: any = newestPostsApi.res.data;
                const typePrefs = type === 'topic' ? prefData.topics : prefData[type];
                if (typePrefs && typePrefs.indexOf(option) > -1) {
                    setChecked(true);
                }
                setUserPrefs(prefData);
            }
        };
        getUserPrefs(); // get initial data
    }, []);

    const handleFollow = async (event: any) => {
        const prefsApi: IfaceAllNewestResponse = await fetchUserPreferences();
        if (prefsApi.loaded && !prefsApi.error) {
            let updatedPrefs: any = { ...prefsApi.res.data };
            if (updatedPrefs[type] === null) {
                updatedPrefs[type] = [option];
            } else if (event.target.checked && updatedPrefs[type].indexOf(option) === -1) {
                updatedPrefs[type].push(option);
            } else if (!event.target.checked && updatedPrefs[type].indexOf(option) > -1) {
                const index = updatedPrefs[type].indexOf(option);
                updatedPrefs[type].splice(index, 1);
            }
            if (Object.keys(prefsApi.res.data).length > 0) {
                const updatedPreferences: IfaceAllNewestResponse = await updateUserPreferences(updatedPrefs);
                if (updatedPreferences.loaded && !updatedPreferences.error) {
                    setUserPreferences(updatedPreferences.res.data)
                }
            }
        } else {
            const response: any = prefsApi.res;
            if(response.message && response.message.includes('code 419')) {
                resetXSRFToken();
            }
        }
    }

    return (
        <li className={`${maxDisabled && !checked ? `disabled ${styles.disabled}` : ''}`} key={`follow-option-${index}`}>
            <label htmlFor={`topic-input-${index}`} className="preference-item">{option}</label>
            <div className={`${styles.contentContainer} test`}>
                <p>{option}</p>
                <input id={`topic-input-${index}`} type="checkbox" name="follow" value={option} onChange={(e) => {
                    setChecked(e.target.checked);
                    handleFollow(e);
                }} checked={checked} disabled={maxDisabled && !checked} />
            </div>
        </li>
    )
}

function useOutsideAlerter(ref: any, setShowShare: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowShare(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function FollowButton({ page, additionalClass = null }: Props) {
    const [showFollow, setShowFollow] = useState<boolean>(false);
    const [userPreferences, setUserPreferences] = useState<any>({});
    const [activePrefCount, setActivePrefCount] = useState<number>(0);
    const [options, setOptions] = useState<any>([]);

    const getUserPrefs = async () => {
        const newestPostsApi: IfaceAllNewestResponse = await fetchUserPreferences();
        const prefData: any = newestPostsApi.res.data;
        if (userPreferences !== prefData) {
            setUserPreferences(prefData);
        }
    };

    useEffect(() => {
        getUserPrefs(); // get initial data
    }, []);

    const updatePrefCount = () => {
        if (userPreferences) {
            let updatedCount = 0;
            for (let key in userPreferences) {
                if (userPreferences[key] && key === 'queries') {
                    updatedCount += userPreferences[key].length;
                }
            }
            if (activePrefCount !== updatedCount) {
                setActivePrefCount(updatedCount);
            }
        }
    }

    useEffect(() => {
        updatePrefCount();
    }, [userPreferences]);

    function updateOptions() {
        let updatedOptions: any = [];

        if (page.res) {
            if (page.res.data.tags && page.res.data.tags.length > 0) {
                page.res.data.tags.map((tag: any) => {
                    if (typeof tag.name === 'object' && tag.name.en) {
                        updatedOptions.push(tag.name.en);
                    } else {
                        updatedOptions.push(tag.name);
                    }
                })
            } else {
                if (page.res.data.market) {
                    updatedOptions.push(page.res.data.market);
                }

                if (page.res.data.categories) {
                    page.res.data.categories.map((cat: any) => {
                        if (cat.name === undefined && cat.title !== undefined) {
                            cat.name = cat.title;
                        }
                        updatedOptions.push(cat.name);
                    });
                }

                if (page.res.data.agencies) {
                    page.res.data.agencies.map((item: any) => {
                        updatedOptions.push(item.name);
                    });
                }
            }
        }

        if (updatedOptions.length > 0) {
            setOptions(updatedOptions);
        }
    }

    useEffect(() => {
        if (page) {
            updateOptions();
        }
    }, [page]);

    // Close on click outside
    const followRef = useRef(null);
    useOutsideAlerter(followRef, setShowFollow);

    return (
        <div id={'article-follow-container'}
            className={`follow-container ${cardStyles.followContainer} ${showFollow && 'open'} ${additionalClass ? additionalClass : ''}`} ref={followRef}>
            <button onClick={() => {
                showFollow ? setShowFollow(false) : setShowFollow(true);
            }} className={`${styles.actionButton} actionButton ${styles.followButton} followButton`}>
                {showFollow ? <Close /> : <Plus />}
                <span>Follow</span>
            </button>
            {showFollow && (
                <div className={`follow-options-container ${cardStyles.followOptionsContainer}`}>
                    <div className={`modal-background`} onClick={() => {
                        showFollow ? setShowFollow(false) : setShowFollow(true);
                    }}></div>
                    <button className={`closeBtn ${styles.closeBtn}`} onClick={() => {
                        showFollow ? setShowFollow(false) : setShowFollow(true);
                    }}>
                        <Close />
                        <span>Close follow options</span>
                    </button>
                    {activePrefCount >= 10 && (
                        <div className={`max-count-container ${cardStyles.maxCountContainer}`}>
                            <div className={`top ${cardStyles.top}`}>
                                <p>10 Interests Followed</p>
                            </div>
                            <div className={`bottom ${cardStyles.bottom}`}>
                                <p>You’ve followed the maximum number of interests
                                    allowed. To follow something different, remove one of your interests. </p>
                                <a href={'/profile'}>Customize Interests</a>
                            </div>
                        </div>
                    )}
                    {options && options.length > 0 && (
                        <ul>
                            {options.map((option: any, index: any) => {
                                return (
                                    <FollowOption
                                        key={`follow-option-${index}`}
                                        option={option}
                                        index={index}
                                        userPreferences={userPreferences}
                                        setUserPreferences={setUserPreferences}
                                        activePrefCount={activePrefCount}
                                    />
                                )
                            })}
                        </ul>
                    )}
                </div>
            )}
        </div>
    )
}

export default FollowButton;